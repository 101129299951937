.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}

.modal :global(.ant-form-item) {
  margin-bottom: 15px !important;
}

.card {
  margin-bottom: 10px;
}

.card :global(.ant-card-body) {
  padding: 12px 12px 0px 12px;
}
