.container {
  font-family: Manrope;
  color: var(--main-text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.userChoosenTop {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
}

.mainInfo {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.infContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 70%;
  justify-content: flex-end;
}

.changeStatus {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 30px;
}

.changeStatus > span:nth-child(1),
.changeStatus > span:nth-child(2) {
  font-size: 18px;
  line-height: 18px;
}

.changeStatus > span:nth-child(1) {
  color: #868686;
}

.changeStatus > span:nth-child(2) {
  color: #000 !important;
}

.stopButton {
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #f40909 !important;
  color: #f40909 !important;
  width: 55px;
  height: 20px;
  border-radius: 24.5px;
  padding: 0;
}

.infoItemWrapper {
  display: flex;
}

.infoItem {
  width: 100%;
}

.copyBtn {
  color: #eda10e;
  font-weight: 500;
  font-size: 18px;
}
.copyBtn span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: -2px;
}
.copyBtn:hover {
  background-color: transparent !important;
}

.buttonChat {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  border-width: 2px;
  line-height: 14px;
  color: var(--main-text-color) !important;
  margin-top: 2px;

  width: 66px;
  height: 34px;
  border-radius: 50px;
}

.infoModern {
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  background-color: #f2f4f5;
  padding: 24px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.piecesInfo {
  display: flex;
  gap: 10px;
}
.pieceInfo {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 20px;
  font-size: 18px;
}

.lessorId {
  font-size: 18px;
}
.pieceInfo > span:nth-child(1) {
  color: #868686;
}

.pieceInfo > span:nth-child(2) {
  color: #000;
}

.editButton {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000 !important;

  width: 49px;
  height: 20px;
  border-radius: 24.5px;
  border: 1px solid #000000 !important;
  margin: 0;
}

.pricesAndDate {
  display: flex;
  gap: 20px;
  padding: 0 0 0 20px;
}

.pricesWrapper {
  background-color: #f2f4f5;
  width: 40%;
  height: 165px;
  padding: 24px;
  border-radius: 10px;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price {
  display: flex;
  align-items: center;
}

.priceWrapper .price:nth-child(1) {
  span:nth-child(1) {
    font-size: 24px;
    font-weight: 700;
    margin-right: 4px;
    line-height: 24px;
  }

  span:nth-child(2) {
    font-size: 16px;
    font-weight: 400;

    margin-right: 10px;
  }

  span:nth-child(3) {
    font-size: 16px;
    font-weight: 400;

    color: #868686;
  }
}

.priceWrapper .price:nth-child(2),
.priceWrapper .price:nth-child(3) {
  span:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    margin-right: 4px;
    line-height: 18px;
  }

  span:nth-child(2) {
    font-size: 16px;
    font-weight: 400;

    margin-right: 10px;
  }

  span:nth-child(3) {
    font-size: 16px;
    font-weight: 400;

    color: #868686;
  }
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #f2f4f5;
  border-radius: 10px;
  padding: 24px;
}

.expandBtn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0;
  margin: 0;

  color: #868686 !important;
  font-size: 18px;
  font-weight: 500;
}

.notWorkingDaysWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
}

.notWorkingDaysWrapper div:first-child,
.notWorkingDaysWrapper div:last-child {
  font-weight: 500;
  font-size: 18px;
}

.notWorkingDaysWrapper div:first-child {
  color: #868686;
}

.notWorkingDaysWrapper div:last-child {
  color: #000000;
}

.daysAndRangeWrapper {
  display: flex;
  gap: 30px;
}
.daysAndRangeWrapper > div {
  width: 50%;
}

.days > div:last-child {
  overflow-y: auto;
  scrollbar-width: none;
  max-height: 270px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.days > div:first-child {
  margin-bottom: 10px;
  color: #868686;
}

.range > div:last-child {
  overflow-y: auto;
  scrollbar-width: none;
  max-height: 270px;
}

.range > div:first-child {
  margin-bottom: 10px;
  color: #868686;
}

.dateContentWrapper {
  font-size: 18px;
  font-weight: 500;
}

.rating {
  padding: 0 20px;
}

.tabs {
  margin-top: 48px;
}

.declineBtn {
  border-radius: 50px;
  width: 83px;
  height: 34px;
  font-size: 14px;
  color: var(--main-text-color) !important;
  font-weight: 700;
  font-family: Manrope;
}

.confirmBtn {
  border-radius: 50px;
  width: 88px;
  height: 34px;
  font-size: 14px;
  color: var(--main-text-color) !important;
  font-weight: 700;
  font-family: Manrope;
}
