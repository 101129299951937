.switcherContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
}

.buttonsWrapper {
  display: flex;
  position: relative;
  border: 2px solid #ffcc4a;
  border-radius: 50px;
  padding: 4px;
  background-color: transparent;

  :global(.ant-btn) {
    border-radius: 50px;
    z-index: 100;

    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #000;
    margin: 0;
  }

  :global(.ant-btn):nth-child(1) {
    width: 125px;
  }

  :global(.ant-btn):nth-child(2) {
    width: 125px;
  }
  :global(.ant-btn):nth-child(3) {
    width: 152px;
  }

  :global(.ant-btn):hover {
    background-color: transparent !important;
  }
}

.optionButton {
  flex: 1;
  border: none;
  background: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.optionButton.selected {
  color: white;
}

.indicator {
  position: absolute;
  top: 4px;
  bottom: 4px;
  background-color: #ffcc4a;
  transition: all 0.3s ease;
  z-index: 0;

  border-radius: 50px;
}
