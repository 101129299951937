:where(.css-jss5sr).ant-menu-dark .ant-menu-item-selected,
:where(.css-jss5sr).ant-menu-dark > .ant-menu .ant-menu-item-selected {
  color: #000;
}

:where(.css-jss5sr).ant-btn-primary {
  color: #000;
  background: #ffcc4a;
  box-shadow: 0 2px 0 rgba(255, 222, 5, 0.06);
}

:where(.css-dev-only-do-not-override-jss5sr).ant-drawer {
  overflow-x: auto;
}
:where(.css-dev-only-do-not-override-jss5sr).ant-menu-dark .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-jss5sr).ant-menu-dark > .ant-menu .ant-menu-item-selected {
  color: #000;
}

:where(.css-dev-only-do-not-override-jss5sr).ant-btn-primary {
  color: #000;
  background: #ffcc4a;
  box-shadow: 0 2px 0 rgba(255, 222, 5, 0.06);
}

:root {
  --main-text-color: #3e3e3e;
}
