.closeButtom {
  position: absolute;
  right: 5%;
  top: 101%;
}

.transferChatButton {
  position: absolute;
  right: 5%;
  bottom: 101%;
}
