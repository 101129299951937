.container {
  font-family: Manrope;
  color: var(--main-text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.userChoosenTop {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
}

.mainInfo {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switcherContainer {
  display: flex;
  align-items: center;
}

.chatAndMainInfo {
  margin-top: 22px;
}

.tabsWrapper {
  margin-top: 48px;
}
