.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .meMode {
    margin-left: 20px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
}
