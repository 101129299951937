.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 25px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.navButton {
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  font-family: Manrope;
}

.navButton span {
  color: #868686;
}

.revText {
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.revText:nth-child(2) {
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #868686;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 3px;
  margin-bottom: 30px;
  width: 100%;
  height: 488px;
  scrollbar-width: thin;
  overflow-y: auto;

  :global(.infinite-scroll-component__outerdiv) {
    height: 100%;
  }
}

.infiniteScroll {
  height: 100%;
  scrollbar-width: none;
}

.rateContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.spin {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100px;
}

.watchAllWrapper {
  gap: 20px;
  display: flex;
  align-items: center;
}

.watchAllWrapper > span:nth-child(1),
.watchAllWrapper > span:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}
.watchAllWrapper > span:nth-child(1) {
  color: var(--main-text-color);
}
.watchAllWrapper > span:nth-child(2) {
  color: #868686;
}

.rate {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #868686;
  font-weight: 700;
  gap: 2px;
}

.rateComponent {
  font-size: 16px;
  :global(.ant-rate-star):not(:last-child) {
    margin-inline-end: 4px;
  }
}

.reviewWrapper:not(:last-child) {
  margin-bottom: 20px;
}
