.badge {
  :global(.ant-badge-count) {
    top: 8px;
    right: -12px;
  }
}

.methodsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.infoIcon {
  display: block;
  background: url("/src/assets/svg/Info.svg") no-repeat;
  background-size: 15px;
  width: 16px;
  height: 16px;
  background-size: contain;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balanceWrapper {
  width: 336px;
  height: 140px;
  padding: 20px;
  border-radius: 16px;
  background-color: #f2f4f5;

  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #000;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.number {
  font-size: 24px;
}
