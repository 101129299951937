.badge {
  :global(.ant-badge-count) {
    top: 8px;
    right: -12px;
  }
}

.container {
  :global(.ant-table-cell):nth-child(5) {
    width: 170px;
  }
  :global(.ant-table-cell):nth-child(4) {
    width: 170px;
  }
}
