.modal {
  :global(.ant-modal-content) {
    width: 605px;
    height: 669px;
    background-color: #f2f4f5;
    padding: 30px;

    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
  }

  :global(.ant-modal-title),
  :global(.ant-modal-content) {
    background-color: #f2f4f5;
  }

  :global(.ant-modal-title) {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }

  :global(.ant-modal-close) {
    top: 30px;
    :global(.ant-modal-close-x) {
      font-weight: 700;
      line-height: 24px;
      font-size: 24px;
      color: black;
    }
  }
}
