.datePickerRange {
  width: 244px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 28px;

  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0%;

  :global(.ant-picker-suffix) {
    top: 8px !important;
  }
  :global(.anticon-calendar) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
.datePickerRange :global(.ant-picker) {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}
.datePickerRange :global(.ant-picker-input) {
  width: 78px;
}

.datePickerRange :global(.ant-picker-input) input {
  color: #868686;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

.datePickerRange :global(.ant-picker-suffix) {
  position: absolute;
  top: 12px;
  right: 10px;
}

.popup {
  font-family: Manrope;
  :global(.ant-picker-panels) {
    background-color: #f2f4f5;
  }

  :global(.ant-picker-cell-in-range)::before {
    background: #ffcc4a !important;
  }

  :global(.ant-picker-cell-range-end)::before {
    background: #ffcc4a !important;
  }

  :global(.ant-picker-cell-range-end) {
    :global(.ant-picker-cell-inner) {
      color: black !important;
      font-weight: 700;
      font-size: 14px;
      border-start-end-radius: 56px !important;
      border-end-end-radius: 56px !important;
    }
  }

  :global(.ant-picker-cell-range-start)::before {
    background: #ffcc4a !important;
  }

  :global(.ant-picker-cell-range-start) {
    :global(.ant-picker-cell-inner) {
      color: black !important;
      font-weight: 700;
      font-size: 14px;
      border-start-start-radius: 56px !important;
      border-end-start-radius: 56px !important;
    }
  }

  :global(.ant-picker-header) {
    border: none;
  }

  :global(.ant-picker-header-view),
  :global(.ant-picker-date-panel) :global(.ant-picker-content) th {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: black;
  }

  :global(.ant-picker-header-super-next-btn),
  :global(.ant-picker-header-super-prev-btn) {
    display: none !important;
  }
  :global(.ant-picker-panel-container) {
    border-radius: 16px !important;
    border: 1px solid #e5e5e5;
  }

  td {
    padding: 3px 0 3px 0 !important;
  }
}
