.selectData {
  width: 190px;
  height: 40px;
  padding: 0;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
  overflow: hidden;

  :global(.ant-select-dropdown) {
    width: 100%;
  }
  :global(.ant-select-selector) {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border: none !important;
    :global(.ant-select-selection-overflow) :global(.ant-select-selection-overflow-item):last-child {
      background-color: transparent !important;
    }
  }

  :global(.ant-select-selection-overflow) :global(.ant-select-selection-overflow-item):first-child {
    max-width: 111px !important;
    margin-right: 8px;
  }
  :global(.ant-select-selection-item) {
    background: #e5e5e5 !important;
    padding: 6px 6px 5px 6px !important;
    border-radius: 40px !important;
    height: 28px !important;
    margin-right: 0 !important;
  }

  :global(.ant-select-selection-overflow-item-suffix) {
    display: none !important;
  }

  .rotate90 {
    transform: rotate(180deg);
  }

  span[title="All"] {
    background-color: transparent !important;

    :global(.ant-select-selection-item-remove) {
      display: none !important;
    }

    :global(.ant-select-selection-item-content) {
      color: var(--main-text-color);
    }
  }

  :global(.ant-select-selection-item-content) {
    color: #868686;
  }
}

.dropDown {
  :global(.rc-virtual-list-scrollbar-thumb) {
    display: none;
  }
  :global(.ant-select-item) {
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    padding: 0 !important;
  }

  :global(.ant-select-item):last-child {
    border-bottom: none;
  }

  :global(.ant-select-item-option-content) {
    white-space: unset !important;
    padding: 11px 10px !important;
  }

  :global(.ant-select-item-option-selected) {
    background-color: transparent !important;
    :global(.ant-select-item-option-content) {
      font-weight: 700 !important;
    }
  }
}
.choosen {
  color: #868686;
  background-color: #e5e5e5;
  padding: 0px 5px 0px 5px;
  border-radius: 40px;
  display: flex;
  align-items: center;
}

.all {
  padding-left: 5px;
}

.showIcon {
  background: url("/src/assets/svg/dropDownArchive.svg") 24px;
  width: 24px;
  height: 24px;
  transition: 0.5s;
  transform: rotate(90deg);
}

:global(.ant-select-open) .showIcon {
  transform: rotate(-90deg);
  transition: 0.5s;
}

:global(.ant-select-dropdown) {
  border-radius: 16px !important;
}

:global(.ant-select-item-option-content) {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
