.flexDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 24px;
  height: 24px;
}
