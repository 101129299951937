.text {
  font-family: Mont-bold;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--main-text-color);
}
