.container {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0%;
  color: #3e3e3e;
}

.btnBack {
  display: flex;
  align-items: center;
}

.btnTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.declineBtn {
  width: 139px;
  height: 34px;
  border-width: 2px;

  border-radius: 50px;
  font-size: 14px;
  color: var(--main-text-color) !important;
  font-weight: 700;
  font-family: Manrope;
  padding: 0;
}

.confirmBtn {
  width: 123px;
  height: 34px;
  border-radius: 50px;
  font-size: 14px;
  color: var(--main-text-color) !important;
  font-weight: 700;
  font-family: Manrope;
  padding: 0;
}

.btn {
  width: 92px;
  height: 34px;
  border-radius: 50px;
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
}

.top {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.grayTitle {
  font-family: Manrope;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0%;
  color: #868686;
  margin-right: 10px;
}

.dateTitle {
  font-family: Manrope;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0%;
}

.copyBtn {
  color: #eda10e;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.copyBtn span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
}
.copyBtn:hover {
  background-color: transparent !important;
}

.changeStatus {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 0 auto;
  font-weight: 500;
}

.changeStatus > span:nth-child(1),
.changeStatus > span:nth-child(2) {
  font-size: 18px;
  line-height: 18px;
}

.changeStatus > span:nth-child(1) {
  color: #868686;
}

.changeStatus > span:nth-child(2) {
  color: #000 !important;
}

.userChat {
  width: 100%;
  height: calc(100vh - 220px);
}
