.container {
  font-family: Manrope;
  font-size: 14px;
  position: relative;
  width: 57.7%;
  height: 422.4px;
  line-height: 14px;
  border-radius: 10px;
  background-color: #f2f4f5;
}

.layer {
  font-weight: 700;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
.button {
  width: 201px;
  height: 38px;
  border-radius: 50px;

  font-weight: 700;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--main-text-color);

  margin-top: 20px;
}
