.wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  align-items: end;
}

.selectorsDataWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: end;
  width: 100%;
  gap: 16px;
}

.selectdataWrapper {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.selectLabel {
  margin-bottom: 8px;
  color: var(--main-text-color);
}

.resetButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 40px;
  border-radius: 28px;
  color: var(--main-text-color) !important;
  border: none;
  background-color: #e5e5e5 !important;
  margin-bottom: 2px;

  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  :global(.ant-btn-icon) {
    margin: 4px !important;
  }
}

.inputSearch {
  width: 268px;
  height: 40px;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
}
