.columnSort {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
}

.button {
  width: 80px;
  height: 34px;
  border-radius: 50px;

  color: var(--main-text-color);
  margin-right: 20px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
