.switcherContainer {
  display: flex;
  z-index: 2;
  align-items: center;
  border-radius: 60px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
}

.switcherLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  width: 150px;
  height: 40px;
  background-color: #000000;
  border-radius: 20px;
  padding: 0px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
}

.switcherLabel input {
  display: none;
}

.switcherSlider {
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 10px;
  top: 5px;
  left: 5px;
  width: 70px;
  height: 30px;
  border-radius: 60px;
  background-color: #ffcc4a;
  transition: transform 0.3s;
  z-index: 0;
  color: black;
}

.switcherLabel input:checked + .switcherSlider {
  transform: translateX(70px);
}

.switcherLabel input:checked + .darkText {
  color: #ffffff;
}

.text {
  z-index: 10;
}
