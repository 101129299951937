.container {
  width: 100%;
  max-width: 1440px;
  min-height: 660px;
  background-color: #ffffff;
  padding: 0px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.messenger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  background-color: #f2f4f5;
  height: calc(100vh - 260px);
  min-height: 300px;
  position: relative;
}

.connections {
  flex: 1;
  min-width: 380px;
  height: 100%;
  background-color: #ececec;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.chatWrapper {
  position: relative;
  flex: 3;
  height: 100%;
  display: flex;
}

.chat {
  flex: 3;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 40px 0px;
}

.supportButton,
.connectButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  height: 96px;
}

.acrive {
  background-color: #fff !important;
}

.supportButton {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #ececec;
  justify-content: flex-start;
}

.connectButton {
  font-size: 18px;
  color: #000000;
  background-color: #ececec;
}

.image {
  background-color: #ffffff;
  border-radius: 50%;
}

.connectButton:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.textWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.supportText {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

.textTitle {
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}

.picture {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.pictureWrapper {
  margin-left: auto;
}

.pictureWrapper :global(.ant-badge-status-dot) {
  width: 14px !important;
  height: 14px !important;
}

.myMessages,
.otherMessages {
  border-radius: 10px;
  padding: 20px;
  margin: 0px 20px 20px 20px;
}

.myMessages {
  align-self: flex-start;
  background-color: #ffffff;
}

.otherMessages {
  align-self: flex-end;
  background-color: #e4e4e4;
}

.messageDate {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

.message {
  margin-top: 5px;
  font-weight: 600;
}

.input {
  width: 100%;
  border-radius: 18px;
  padding: 5px 35px 5px 8px;
}

.inputBox {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  margin: 10px 20px 20px 20px;
}

.sendButton {
  cursor: pointer;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  background: url("../../../../../assets/svg/planeright.svg");
  background-repeat: no-repeat;
  position: absolute;
  right: 7px;
  top: 5px;
}

.sendButton:hover {
  transform: scale(1.1);
}

.supportTitle {
  padding-left: 20px;
  position: relative;
}

.supportTitle:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../../../../../assets/svg/headphones.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 5px;
}

.buttonStatusWrapper button {
  margin: 5px;
}

.paginationComponent {
  padding: 10px;

  :global(.ant-pagination-options) {
    display: none;
  }
}

.buttonsWithSearch {
  display: flex;
  width: 75%;
  align-items: center;
}

.inputSearchField {
  height: 32px;
}
