.badge {
  :global(.ant-badge-count) {
    top: 8px;
    right: -12px;
  }
}

.tabs {
  div[data-node-key="5"] {
    margin-left: 20px !important;
    border-bottom: 1px solid rgba(198, 197, 197, 1) !important;
  }
  div[data-node-key="5"]:hover {
    color: var(--main-text-color);
  }
  :global(.ant-tabs-nav)::before {
    width: 635px;
  }
}
