.arrow {
  width: 24px;
  height: 24px;
  background: url("../../../assets/svg/dropDownArchive.svg") no-repeat;
  transform: rotate(-90deg);
}

.arrowUp {
  width: 24px;
  height: 24px;
  background: url("../../../assets/svg/dropDownArchive.svg") no-repeat;
  transform: rotate(90deg);
}

.arrowLeft {
  width: 24px;
  height: 24px;
  background: url("../../../assets/svg/dropDownArchive.svg") no-repeat;
  transform: rotate(180deg);
}

.arrowRight {
  width: 24px;
  height: 24px;
  background: url("../../../assets/svg/dropDownArchive.svg") no-repeat;
  transform: rotate(0deg);
}
