.rateLimitsButton {
  border: 2px solid;
  border-radius: 50px;
  width: 109px;
  height: 34px;

  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;

  padding: 0;
  color: var(--main-text-color) !important;
}
