.warning {
  background: url("/src/assets/svg/Spinner.svg") no-repeat;
  width: 24px;
  height: 24px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: 1.2s linear 0s normal none infinite running spin;
  -webkit-animation: 1.2s linear 0s normal none infinite running spin;
}
