.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.closeButton {
  width: 100%;
  height: 38px;
  gap: 0px;
  border-radius: 50px;

  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--main-text-color);
}
.inputSearch {
  width: 100%;
  height: 40px;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
  margin: 24px 0;
}

.modal {
  width: 560px !important;
  :global(.ant-modal-title) {
    background-color: #f2f4f5;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  :global(.ant-modal-content) {
    background-color: #f2f4f5;
  }

  :global(.ant-modal-close) {
    top: 20px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 3px;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  overflow-y: auto;

  :global(.infinite-scroll-component__outerdiv) {
    height: 100%;
  }
}

.infiniteScroll {
  max-height: 488px;
  scrollbar-width: thin;
}

.reviewWrapper:not(:last-child) {
  margin-bottom: 14px;
}
