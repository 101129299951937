.wrapper {
  :global(.ant-table-thead) {
    :global(.ant-table-cell):nth-child(1) {
      width: 158px;
    }
    :global(.ant-table-cell):nth-child(2) {
      width: 159px;
    }
    :global(.ant-table-cell):nth-child(3) {
      width: 159px;
    }
    :global(.ant-table-cell):nth-child(4) {
      width: 159px;
    }
    :global(.ant-table-cell):nth-child(5) {
      width: 159px;
    }
    :global(.ant-table-cell):nth-child(6) {
      width: 159px;
    }
  }
}

.columnSort {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: end;

  gap: 10px;
}

.button {
  width: 80px;
  height: 34px;
  border-radius: 50px;

  color: var(--main-text-color);

  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
