.info {
  font-size: 18px;
}

.copyBtn {
  color: #eda10e;
  font-weight: 500;
  font-size: 18px;
  height: 0px;
  line-height: 15px;
}
.copyBtn span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: -2px;
}
.copyBtn:hover {
  background-color: transparent !important;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  height: 74px;
}
