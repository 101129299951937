.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}
