.uploadFiles {
  position: absolute;
  z-index: 100;
  bottom: 32.7px;
  .paperClipBtn {
    position: absolute;
    top: 100%;
    left: 8px;
  }
}
