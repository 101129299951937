.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}

.header {
  font-size: 16px;
  font-weight: bold;
}

.input {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin: 0px;
  font-size: 14px;
  padding: 4px 11px;
}

.input:focus,
.input:active,
.input:hover {
  border-color: #ffdc73;
  outline: none;
}

.geoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-bottom: 20px;
}

.spin {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
