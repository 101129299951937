.feature {
  display: flex;
  gap: 5px;
}

.feature div:first-child {
  font-size: 14px;
  color: #868686;
  width: 174px;
}

.feature div:last-child {
  font-size: 14px;
  width: 100%;
}
