.modal {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #3e3e3e;
  padding: 0;

  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    background-color: #f2f4f5;
  }

  :global(.ant-modal-content) {
    width: 545px;
    padding: 32px;
  }

  :global(.ant-modal-header) :global(.ant-modal-title) {
    font-weight: 700;
    color: black;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 48px;
  }

  :global(.ant-modal-close) {
    top: 32px;
    right: 32px;
  }

  .content {
    font-weight: 500;
    font-size: 24px;
    line-height: 26.4px;
    text-align: center;
    margin-bottom: 24px;
    letter-spacing: -0.6px;
  }

  .inputArea {
    height: 142px;
    border-radius: 18px;
    border: 1px solid #e5e5e5;
    padding: 8px 16px;
    scrollbar-width: thin;
    resize: none;

    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #3e3e3e;

    white-space: pre-wrap;
  }

  .btnWrapper {
    display: flex;
    justify-content: space-between;
    :global(.ant-btn) {
      width: 231px;
      height: 38px;
      border: 2px solid transparent;
      border-radius: 50px;
      font-size: 14px;
      line-height: 14px;
      font-family: Manrope;
      font-weight: 400;
      color: #3e3e3e;
    }

    :global(.ant-btn):nth-child(1) {
      border: 2px solid #e5e5e5;
    }

    :global(.ant-btn):nth-child(2) {
      font-weight: 700;
    }
  }
  .danger {
    background-color: transparent;
    border-color: #ff7875 !important;
  }
}
