.modal {
  width: 605px !important;
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.6px;

  :global(.ant-modal-close-x),
  :global(.ant-modal-title) {
    font-size: 24px;
    color: black;
    font-weight: 700;
  }

  :global(.ant-modal-close) {
    top: 38px;
    right: 25px;
  }

  :global(.ant-modal-content) {
    padding: 30px;
  }

  .cascaderWrapper {
    margin-top: 20px;
  }

  .limitationSwitchWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .switchLimitationLabel {
    font-family: Manrope;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
  }

  .limitationsWrapper {
    :global(.ant-form-item-control-input-content) {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .limitationsWrapper :global(.ant-row) > :global(.ant-col):first-child {
    padding: 0 0 3px 0;
  }

  .attachWarnign span:last-child {
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0%;
  }
  .attachWarnign img:first-child {
    width: 16px;
    height: 16px;
  }
  .attachWarnign {
    margin-top: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 4px;

    padding: 8px 12px;
    border-radius: 10px;
    background-color: rgba(244, 9, 9, 0.1);
  }

  .returnSwithcer {
    margin-bottom: 20px;
  }

  .transReturnDate :global(.ant-form-item-control-input-content) {
    display: flex;
    gap: 20px;
  }

  .compensationWrapper
    > :global(.ant-row)
    > :global(.ant-col)
    > :global(.ant-form-item-control-input)
    > :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .limitationWrapper:last-child {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .switcher {
    height: 30px;
    width: 50px;

    :global(.ant-switch-handle)::before {
      height: 24px;
      width: 24px;
      border-radius: 50px;
    }

    :global(.ant-switch-handle) {
      top: 3px;
    }
  }
  :global(.ant-switch-checked) :global(.ant-switch-handle)::before {
    inset-inline-start: calc(100% - 24px) !important;
  }

  .titleFirst {
    font-family: Manrope;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.4px;
  }

  .labelFormFont {
    label {
      font-family: Manrope;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.4px;
      padding: 0;
    }

    :global(.ant-row) :global(.ant-col) {
      padding-bottom: 4px;
    }
  }

  .date {
    width: 190px;
    height: 40px;
    border-radius: 40px;
    gap: 8px;
    border: 1px solid #e4e4e4;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;

    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.4px;

    :global(.anticon-calendar) {
      font-size: 24px;
    }
  }

  :global(.ant-form-item) {
    margin: 0;
  }

  .rentcompwrap
    > :global(.ant-row)
    > :global(.ant-col)
    > :global(.ant-form-item-control-input)
    > :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .inputAntd {
    width: 262.5px;
    height: 38px;
    border-radius: 40px;
    border: 1px solid #e5e5e5;
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 16px;

    :global(.ant-input) {
      font-family: Manrope;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.4px;
      color: #868686;
    }
  }

  .textArea {
    resize: none;
    width: 545px;
    height: 93px;
    border-radius: 18px;
    border: 1px solid #e5e5e5;
    padding: 8px 16px;
    scrollbar-width: thin;

    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .uploadReport {
    width: 155px;
    height: 30px;
    border-radius: 24.5px;
    border: 2px solid #868686;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Manrope;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0%;
    text-align: center;

    color: #868686;

    :global(.ant-btn-icon) {
      margin-inline-end: 5px !important;
    }
  }

  .saveDraftBtn {
    width: 100%;
    height: 54px;
    border-radius: 50px;
    border: 2px solid #ffcc4a;
  }

  .compliteDuspBtn {
    width: 100%;
    height: 54px;
    border-radius: 50px;
    color: var(--main-text-color);
    border: 2px solid #ffcc4a;
    background-color: #ffcc4a;
  }

  .actionBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    :global(.ant-btn) {
      font-family: Manrope;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0%;
      text-align: center;
      color: var(--main-text-color);
    }
  }

  .upload {
    :global(.ant-upload-list-text) {
      margin-bottom: 20px;
    }
  }

  .warningReturn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 20px;
    background-color: #0000001a;
    border-radius: 10px;
    :global(.ant-btn) {
      width: 155px;
      height: 38px;
      border-radius: 50px;
      color: var(--main-text-color);
      font-family: Manrope;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0%;
      text-align: center;
    }
  }

  .warningReturn div:first-child {
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      filter: grayscale(1);
    }
  }
}
