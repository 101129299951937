.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0px;
}

.countryWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  margin: 20px;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
