.pagination {
  display: flex;
  justify-content: center;
  :global(.ant-pagination-item) {
    font-family: Manrope;
    font-weight: 700;
    font-size: 18px;
  }

  :global(.ant-pagination-next),
  :global(.ant-pagination-prev) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  :global(.ant-pagination-item-active) {
    border: 0;
  }

  :global(.ant-pagination-item) {
    margin: 0;
  }
}

.copyBtn {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.columnSort {
  display: flex;
  align-items: center;
}

.container tbody td:nth-child(1) {
  width: 70px !important;
}

.container tbody td:nth-child(2) {
  width: 15% !important;
}

.container tbody td:nth-child(3) {
  width: 0px !important;
}

.container tbody td:nth-child(4) {
  width: 80px !important;
}
.container tbody td:nth-child(5) {
  width: 12% !important;
}

.container tbody td:nth-child(6) {
  width: 13% !important;
}

.container tbody td:nth-child(7) {
  width: 15% !important;
}

.buttonWrapper {
  margin: 0 0 0 auto;
  width: fit-content;

  color: var(--main-text-color);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button {
  width: 80px;
  height: 34px;
  border-radius: 50px;
  margin-right: 20px;
}
