.container table {
  font-family: "Mont";
  font-size: 16px;
  font-weight: 600;
  background-color: transparent !important;
  border-spacing: 0px 15px;
}

.container tbody td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.container tbody td:first-of-type {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.container tbody td:last-of-type {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.container thead th {
  background-color: #ececec !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 14px !important;
  color: #868686 !important;
}

.container tr {
  background-color: #f2f4f5;
  font-family: 700;
  font-family: "Manrope" !important;
  font-size: 16px !important;
}

.container th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.container th:last-of-type {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.table {
  width: 100%;
}

.container tbody td:nth-child(1) {
  width: 92px;
}

.container tbody td:nth-child(2) {
  width: 206px;
}

.container tbody td:nth-child(3) {
  width: 166px;
}

.container tbody td:nth-child(4) {
  width: 150px;
}
.container tbody td:nth-child(5) {
  width: 65px;
}
.paginationBottom {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  :global(.ant-pagination-item-ellipsis) {
    top: 5px !important;
  }

  :global(.ant-pagination-item) {
    border: none;
  }
}
