.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.divider::before {
  width: calc(0.2 * 100%) !important;
}

.titleWrapper {
  display: flex;
  gap: 25px;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}

.buttonWrapper,
.formWrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.formWrapper {
  gap: 100px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin: 20px;
}

.table {
  :global(.ant-table-row) :global(.ant-table-cell):nth-child(2) {
    width: 70px;
  }

  :global(.ant-table-row) :global(.ant-table-cell):nth-child(3) {
    width: calc(50vw - 425px);
  }

  :global(.ant-table-row) :global(.ant-table-cell):nth-child(5) {
    width: calc(50vw - 497px);
  }

  :global(.ant-table-expanded-row) td:nth-child(1) {
    background-color: #fff;
  }
}

.tableOptions {
  :global(.ant-table-row) :global(.ant-table-cell):nth-child(1) {
    width: 70px;
  }

  :global(.ant-table-row) :global(.ant-table-cell):nth-child(2) {
    width: calc(50vw - 497px);
  }

  :global(.ant-table) {
    margin-inline: 104px -16px !important;
  }
}
