.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}

.buttonWrapper,
.formWrapper {
  display: flex;
  align-items: center;
}

.formWrapper {
  gap: 100px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.noData {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 200px;
  font-size: 20px;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
