.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 226px;
  height: 140px;
  padding: 20px 16px;
  border-radius: 10px;
  background-color: #f4f4f4;

  letter-spacing: -0.6px;

  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--main-text-color);
  .numberWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 11px;
  }
  .defaultSpan {
    margin-top: 12px;
    width: 51px;
    height: 18px;
    line-height: 1px;
    border-radius: 6px;

    background-color: #3e3e3e;
    color: #f2f4f5;

    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date {
    font-weight: 400;
    color: #868686;
    font-size: 14px;
  }
}

.default {
  border: 3px solid #ffcc4a;
}
