.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: fit-content;
  height: 79px;
  width: 100%;

  padding: 16px;

  background-color: #f2f4f5;
  border-radius: 10px;
}

.wrapperOfContent {
  min-width: 128px;
}
.labelWrapper {
  display: flex;
  align-items: flex-end;
  gap: 5px;

  margin-bottom: 10px;

  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #868686;
}
.content {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  align-items: center;
  color: var(--main-text-color);
}

.infoIcon {
  display: block;
  background: url("/src/assets/svg/Info.svg") no-repeat;
  background-size: 15px;
  width: 14px;
  height: 14px;
  background-size: contain;
}

.moreInfo {
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}
