.line {
  display: flex;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
}

.line > span:nth-child(1) {
  width: 10%;
}

.line > span:nth-child(2) {
  width: 25%;
}

.line > span:nth-child(3) {
  width: 23%;
}

.line > span:nth-child(4) {
  width: 42%;
  text-align: right;
}

.separate {
  display: flex;
  justify-content: space-between;
}

.space {
  margin-right: 24px;
  display: block;
}

.clockOutlined {
  svg {
    fill: #ffcc4a;
    margin-right: 10px;
  }
}
