.button {
  font-weight: 600;
  font-size: 18px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 480px;
  gap: 10px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
