.container {
  background-color: #f2f4f5;
  padding: 24px;
  font-family: Manrope;
  font-weight: 400;
  color: var(--main-text-color);
  width: 512px;
  border-radius: 10px;
}

.mainInfoWrapperText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ratingInfo {
  display: flex;
  align-items: center;
}

.mainInfoWithIcon {
  display: flex;
  gap: 10px;
}

.ratingOfUser {
  margin-left: 2px;
  font-size: 20px;
  li:not(:last-child) {
    margin-inline-end: 3px !important;
  }
}

.avatar {
  border: 4px solid #fff;
}

.buttonBlock {
  display: block;
  margin-top: 24px;
  margin-bottom: 18px;
  border-color: #f40909;
  border-width: 2px;
  color: var(--main-text-color) !important;
  width: 123px;
  height: 38px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;

  font-size: 14px;
  font-weight: 700;
}

.description {
  width: 100%;
  height: 124px;
  font-weight: 500;
  font-size: 14px;
  color: var(--main-text-color);
  line-height: 14px;
  margin: 0;
}

.onlineDot {
  width: 8px;
  height: 8px;
  background-color: #47b022;
  border-radius: 50px;
}
