.container {
  display: flex;
  align-items: center;
  .rate {
    width: 70px;
    font-size: 12px;
    margin-bottom: 7px;

    li:not(:last-child) {
      margin-inline-end: 1px;
    }
  }
  .nameRate {
    display: flex;
    flex-direction: column;
    color: #3e3e3e;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    margin-left: 10px;
  }
}
