.draver {
  min-width: 1485px !important;
  max-width: 100%;
  width: 100%;
}

.wrapperDraver {
  width: 200vw;
}

.mask {
  width: 100vw;
  max-width: none;
  min-width: none;
}

.mask + div + :global(.ant-drawer-content-wrapper) {
  height: 556px !important;
}

.container {
  background-color: #ffffff;
  border-radius: 20px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--main-text-color);

  max-width: 1485px;
  margin: 0 auto;
}

.topInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.mainInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mainInfo > span:first-child {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}

.copyBtn,
.mainInfo > span:nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #868686;
}

.copyBtn {
  color: #eda10e;
  font-weight: 500;
}
.copyBtn span {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: -2px;
}
.copyBtn:hover {
  background-color: transparent !important;
}

.buttonChat {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: var(--main-text-color);

  width: 66px;
  height: 34px;
  border-radius: 50px;
}

.closeBtn {
  font-family: Manrope;
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.allInfo {
  background-color: #f2f4f5;
  width: 100%;
  padding: 14px;

  display: flex;
  border-radius: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.historyWrapper {
  background-color: #f2f4f5;
  padding: 10px 12px;
  border-radius: 10px;
}
