.headerAside {
  margin-left: 10px;
}

.layout {
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px 50px 0px 0px;
}

.adminData {
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
}

.wrapper {
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}

.content {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  height: calc(100% - 64px);
  overflow-y: auto;
  width: 1480px;
}
.layoutWrapper {
  width: 1495px !important;
}

.walletWrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
}
