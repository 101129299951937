.flexDisplay {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.ratingOfUser {
  margin-left: 4px;
  font-size: 16px;
  li:not(:last-child) {
    margin-inline-end: 3px !important;
  }
}
.inputSearch {
  width: 268px;
  height: 40px;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
}
.mainInfoReviews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.amountRate {
  display: flex;
  line-height: 22px;
}
.container {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--main-text-color);
}
.reviewsContainer {
  display: flex;
  gap: 40px;
  height: 436px;
}
.reviewsContainerOne,
.reviewsContainerTwo {
  width: 680px;
}

.reviewsContainerOne > div:not(:last-child),
.reviewsContainerTwo > div:not(:last-child) {
  margin-bottom: 20px;
}

.paginationBottom {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  :global(.ant-pagination-item-ellipsis) {
    top: 5px !important;
  }

  :global(.ant-pagination-item) {
    border: none;
  }

  li {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }
}
