.container {
  :global(.ant-table-cell):first-child {
    text-align: center;
  }
}

.columnSort {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
}

.button {
  width: 80px;
  height: 34px;
  border-radius: 50px;
  padding: 0;

  color: var(--main-text-color);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.button:hover {
  color: black !important;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.copybtn {
  color: #3e3e3e;
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0%;
}

.table tbody td:nth-child(4) {
  width: 158px;
}

.table tbody td:nth-child(2) {
  width: 160px;
}

.table tbody td:nth-child(5) {
  width: 150px;
}

.table tbody td:nth-child(6) {
  width: 150px;
}

.table tbody td:nth-child(7) {
  width: 200px;
}

.table :global(.ant-table-cell):first-child {
  text-align: start;
}
