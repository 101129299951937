.debateDetailModal {
  display: flex;
  justify-content: center;
  align-items: center;

  :global(.ant-modal-body) {
    width: 1010px;
    max-height: 81vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  :global(.ant-modal-body)::-webkit-scrollbar {
    display: none;
  }

  :global(.ant-modal-content) {
    padding: 20px;
  }

  .detailsWrapper {
    display: flex;
  }

  .dataContainer {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .itemHeader {
    font-weight: bold;
    font-size: 16px;
  }

  h3.itemHeader {
    padding-top: 20px;
  }

  .itemContainer {
    display: flex;
    align-items: center;
    gap: 3px;
    padding-right: 3px;
  }

  .col {
    display: flex;
    flex-direction: column;
    min-width: 334px;
  }

  .photo {
    margin-bottom: 10px;
  }

  .chatSection {
    position: sticky;
    top: 20px;
    height: calc(81vh - 24px - 187px);
    background-color: #f2f4f5;
    border-radius: 3px;
  }

  .actionsFooter {
    box-shadow: 0px -1px 3px #8585852f;
    padding-top: 20px;
    position: sticky;
    bottom: 0;
    left: -20px;
    background-color: #ffffff;
    border-top: 1px solid #c7c6c662;
  }
}
