.cascaderWrapper {
  font-family: Manrope;
  display: flex;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  :global(.ant-form-item) {
    width: 100%;
    margin-bottom: 20px;
  }

  :global(.ant-select-selection-item) {
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: black;
  }
}

.buttonConfirm {
  width: 104px;
  height: 38px;
  border-radius: 50px;

  font-family: Manrope;
  color: #3e3e3e;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;

  margin: 0 0 0 auto;
}

.cascader {
  margin-right: 20px;

  :global(.ant-select-selection-placeholder) {
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    color: #868686;
  }
}

.cascader :global(.ant-select-selector) {
  border-radius: 40px !important;
}

.search :global(.ant-input-search-button) {
  border-start-end-radius: 40px !important;
  border-end-end-radius: 40px !important;
}

.search :global(.ant-input-affix-wrapper) {
  border-start-start-radius: 40px !important;
  border-end-start-radius: 40px !important;
}

.arrowSpacing {
  margin: 0 0 0 12px;
  width: 16px;
  height: 16px;
  filter: invert(1) !important;
}

.arrowSpacingSearch {
  margin: 0 12px;
  width: 16px;
  height: 16px;
  filter: invert(1) !important;
}

.searchWord {
  font-weight: 700;
}

.searchOpt {
  display: flex;
  align-items: center;
}

.popup {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e3e3e;

  border-radius: 16px;
  background-color: #f2f4f5;

  :global(.ant-cascader-menu):not(:first-child) {
    height: auto;
    max-height: 500px;
  }

  :global(.ant-cascader-menu):not(:last-child) {
    border-inline-end: 2px solid #fff !important;
  }

  :global(.ant-cascader-menu) {
    scrollbar-width: none;
  }

  :global(.ant-cascader-menu-item):not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }

  :global(.ant-cascader-menu-item) {
    padding: 10px 8px 10px 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  :global(.ant-cascader-menu-item-active) {
    background-color: transparent !important;
  }
}
