.tabs {
  width: 100%;
  margin-top: 0px;
}

.tabs :global(.ant-tabs-nav-list) :global(.ant-tabs-tab):nth-last-child(-n + 2) {
  z-index: 12;
}

.tabs :global(.ant-tabs-ink-bar-animated) {
  background-color: #3e3e3e;
}
.tabs :global(.ant-tabs-tab-active) :global(.ant-badge):first-child {
  color: #3e3e3e !important;
}
.tabs :global(.ant-tabs-nav) :global(.ant-tabs-nav-wrap) :global(.ant-tabs-nav-list) :global(.ant-tabs-tab) {
  margin: 0;
}
.tabs :global(.ant-tabs-nav)::before {
  border-bottom: 1px solid #c6c5c5;
}
.tabs :global(.ant-tabs-tab) {
  padding-bottom: 0px !important;
  padding: 12px 20px;
}

.tabs :global(.ant-badge):first-child {
  color: #868686;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tabs > :global(.ant-tabs-nav) {
  width: max-content;
}

.tabs :global(.ant-tabs-nav-operations) {
  display: none !important;
}
