.item {
  margin: 0;
  height: 160px;
  color: "#fff";
  line-height: 160px;
  text-align: "center";
  background: "#364d79";
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.wrapperCarousel {
  width: 525px;
  background-color: #f2f4f5;
  padding: 10px;
  border-radius: 5px;
}

.carousel {
  width: 100%;
  height: 408px;

  img {
    object-fit: contain;
  }
}
.carousel :global(.anticon-left) {
  left: 10px;
}

.carousel :global(.slick-prev),
.carousel :global(.slick-prev:hover) {
  left: 10px;
  padding-top: 2px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.carousel :global(.slick-next),
.carousel :global(.slick-next:hover) {
  left: unset;
  right: 10px;
  padding-top: 2px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.carousel :global(.slick-next:after),
.carousel :global(.slick-prev:after) {
  top: 6px;
  left: 6px;
}

.seen {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 63px;
  height: 20px;
  display: flex;
  gap: 4px;
  color: #fff;
  background-color: #000;
  padding: 4px;
  border-radius: 3px;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.segmentWrapper {
  max-width: 525px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
  overflow-x: scroll;
  scrollbar-width: none;
  scrollbar-color: #e5e5e5 white;
}
:global(.slick-prev::before),
:global(.slick-next::before) {
  display: none;
}

.segmentWrapper :global(.ant-segmented-item) {
  border: 1px solid white;
}

.segmentWrapper :global(.ant-segmented-item-label) {
  padding: 0px;
}

.segmentWrapper :global(.ant-segmented-group) {
  gap: 10px;
}

.segmentWrapper :global(.ant-segmented-item-selected) {
  border: 1px solid #ffcc4a;
}
