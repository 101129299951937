.listItem {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
}

.text {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.itemHeader {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #3e3e3e;
  margin-bottom: 5px;
}

.itemMessage {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #868686;
}

.rating {
  margin-left: auto;
  min-width: 88px;
  color: #868686;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.ratingDate {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.inputBox {
  position: relative;
  width: calc(100% - 40px);
  box-sizing: border-box;
  margin: 0px;
}

.input {
  width: 100%;
  border-radius: 18px;
  padding: 5px 35px 5px 8px;
}

.sendButton {
  cursor: pointer;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  position: absolute;
  right: 7px;
  top: 5px;
}

.sendButton:hover {
  transform: scale(1.1);
}

.container {
  position: relative;
}

.commentsTreeWrapper {
  display: flex;
  flex-direction: column;
}

.commentTree {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 10px;
}

.commentAvatarWrapper {
  flex-direction: row;
  width: 80%;
}

.ratingRate {
  font-size: 16px;

  :global(.ant-rate-star):not(:last-child) {
    margin-inline-end: 4px;
  }
}

.button {
  height: 40px;
  width: 40px !important;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  background-color: #f2f4f5;
  margin-left: 20px;
  align-self: center !important;
}
