.button {
  font-weight: 600;
  font-size: 18px;
}

.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.link {
  font-size: 16px;
  border: 1px solid #ffcc4a;
  padding: 5px;
  border-radius: 8px;
}

.buttonWrapper,
.formWrapper {
  display: flex;
  align-items: center;
}

.formWrapper {
  gap: 100px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin: 20px;
}

.wrapper {
  margin-bottom: 20px;
}

.search {
  margin-left: 20px;
}

.tabs {
  width: 100%;
  margin-top: 0px;
}

.tabs :global(.ant-tabs-nav-list) :global(.ant-tabs-tab):nth-last-child(-n + 2) {
  z-index: 12;
}

.tabs :global(.ant-tabs-ink-bar-animated) {
  background-color: #3e3e3e;
}
.tabs :global(.ant-tabs-tab-active) :global(.ant-badge):first-child {
  color: #3e3e3e !important;
}
.tabs :global(.ant-tabs-nav) :global(.ant-tabs-nav-wrap) :global(.ant-tabs-nav-list) :global(.ant-tabs-tab) {
  margin: 0;
}
.tabs :global(.ant-tabs-nav)::before {
  border-bottom: 1px solid #c6c5c5;
}
.tabs :global(.ant-tabs-tab) {
  padding-bottom: 0px !important;
  padding: 12px 18px;
}

.tabs :global(.ant-badge):first-child {
  color: #868686;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tabs > :global(.ant-tabs-nav) {
  width: max-content;
}

.tabs :global(.ant-tabs-nav-operations) {
  display: none !important;
}

.badge {
  :global(.ant-badge-count) {
    top: 8px;
    right: -12px;
  }
}
