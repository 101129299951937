.divider {
  font-size: 18px !important;
  font-weight: bold !important;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
