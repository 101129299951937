.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.error {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
