.container {
  :global(.ant-table-cell):nth-child(5) {
    width: 190px !important;
  }
  :global(.ant-table-cell):nth-child(6) {
    width: 95px !important;
  }
}

.selectWrapper {
  display: flex;
  justify-self: left;
  align-items: center;
  gap: 5px;
}

.infoComponent {
  color: red;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
}

.button {
  width: 80px;
  height: 34px;
  border-radius: 50px;

  color: var(--main-text-color);

  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.columnSort {
  display: flex;
  align-items: center;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  :global(.ant-pagination-item-ellipsis) {
    top: 5px !important;
  }

  :global(.ant-pagination-item) {
    border: none;
  }
}
