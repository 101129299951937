.container {
  width: 525px;

  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--main-text-color);
}

.productDescWrapper {
  margin-bottom: 48px;
}
