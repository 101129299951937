.review {
  display: flex;
  justify-content: space-between;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
}

.avatarName {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-wrap: break-word;
}

.text div:last-child {
  color: #979797;
  font-size: 14px;
}

.text div:first-child {
  color: #000;
}

.dataButton {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rateDate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 46px;
}

.date {
  color: #979797;
  font-size: 14px;
  text-align: right;
  font-weight: 400;
}

.rating {
  font-size: 16px;
  min-width: 112px;
}

.button {
  height: 40px;
  width: 40px !important;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  background-color: #f2f4f5;
}

.commentsTreeWrapper {
  display: flex;
  flex-direction: column;
}

.commentTree {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
}

.commentAvatarWrapper {
  flex-direction: row;
  width: 80%;
}

.itemHeader {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  margin-bottom: 8px;
}

.itemMessage {
  color: #979797;
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
}

.lessorCommentTree {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #eda10e;
  margin-bottom: 5px;
}
